import { mask } from "vue-the-mask";

export const dateMask = "##/##/####";
export const cardDateMask = "##/##";
export const cardMask = "#### #### #### ####";
export const patentMask = "NNNNNNN";
export const phoneMask = "#########";

export const applyMask = {
  bind(el, binding) {
    if (binding.value && binding.value !== "") {
      mask(el, binding);
    }
  },
};

export const rutMask = (value) => {
  const rut = value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return [result.length > 12 ? result.substring(0, 11) : result];
};

export const chasisMask = (value) => {
  const chasis = value.replace(/^0+|[^*0-9a-zA-Z]/g, "").toUpperCase();
  return [chasis];
};

export const motorMask = (value) => {
  const motor = value.replace(/^0+|[^*0-9a-zA-Z]/g, "").toUpperCase();
  return [motor];
};

export const onlyNumberMask = (length = 1) => {
  let resultado = "";
  // Bucle for para concatenar strings
  for (let i = 1; i <= length; i++) {
    resultado += `#`;
  }
  return resultado;
};

<template>
  <v-app>
    <v-row no-gutters>
      <v-col sm="12" md="5" class="d-flex flex-column justify-center">
        <div class="d-flex flex-column h-100">
          <div class="d-flex justify-center mtp-10">
            <v-img class="global-logoimg" src="@/assets/logo-mclick-min.png">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-1"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div class="d-flex justify-center align-center h-100">
            <div class="d-flex justify-center flex-column w-60">
              <div>
                <h3 class="login d-flex justify-center mb-9">Iniciar sesión</h3>
              </div>
              <v-form ref="login">
                <v-card flat class="d-flex flex-column justify-center">
                  <LabelComponent :text="rut.label" />
                  <v-text-field
                    v-model="rut.value"
                    v-mask="rutMask"
                    persistent-placeholder
                    :rules="rut.rules"
                    max_length="12"
                    :placeholder="rut.placeholder"
                    outlined
                    persis
                    autocomplete="username"
                    :error-messages="rut.error"
                    name="LoginUsername"
                  ></v-text-field>
                  <LabelComponent :text="text_password.label" />
                  <v-text-field
                    persistent-placeholder
                    v-model="text_password.text"
                    :type="text_password.type"
                    :placeholder="text_password.placeholder"
                    :rules="text_password.rules"
                    outlined
                    :error-messages="text_password.error"
                    autocomplete="password"
                    name="LoginPassword"
                    data-cy="password-text-field"
                  >
                    <template v-slot:append>
                      <v-icon
                        color="#0077C8"
                        @click="
                          text_password.type == 'password'
                            ? (text_password.type = 'text')
                            : (text_password.type = 'password')
                        "
                        >{{
                          text_password.type == "password"
                            ? "mdi-eye-off-outline"
                            : "mdi-eye-outline"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-card>
              </v-form>

              <div class="d-flex justify-end mb-10">
                <router-link to="/recover-password">
                  <h5>¿Olvidaste tu contraseña?</h5>
                </router-link>
              </div>

              <div class="text-center">
                <v-btn
                  block
                  color="primary"
                  depressed
                  class="text-none"
                  id="loginSubmitBtn"
                  @click="Login()"
                  :loading="isloading"
                >
                  Ingresar
                  <v-icon right>mdi-login</v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center mt-8">
                <h5>¿No tienes una cuenta creada?</h5>
                <router-link to="/signup" class="ml-2">
                  <h5>Registrate aquí</h5>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="7" class="d-none d-md-flex global-bg">
        <v-col sm="12" class="mtp-10">
          <p class="slogan">Gestiona tus ventas a tu manera</p>
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import {
  validarRutFormat,
  validarRut,
  validarContrasena,
} from "@/store/resources/validate.js";
import { rutMask } from "@/helpers/mask.js";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  name: "LoginPage",
  components: {
    LabelComponent,
  },
  data: () => ({
    error: false,
    isloading: false,
    newPassword: false,
    errorUsername: "",
    rut: {
      value: "",
      label: "Usuario",
      placeholder: "Ingrese el numero o nombre de usuario",
      error: "",
      rules: [
        (v) => !!v || "El usuario es requerido.",
        (v) => validarRutFormat(v) || "Formato de RUT invalido",
        (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
      ],
    },
    text_password: {
      label: "Contraseña",
      type: "password",
      placeholder: "Ingrese su contraseña",
      text: "",
      error: "",
      rules: [
        (v) => !!v || "Debe escribir una contraseña.",
        (v) => validarContrasena(v),
      ],
    },
  }),
  created() {
    this.islogged();
  },

  computed: {
    CreateNewPassword() {
      return this.$store.getters.CreateNewPassword;
    },
    rutMask: () => rutMask,
  },

  methods: {
    islogged() {
      if (this.$store.getters.isLoggedIn) {
        this.$router.push({ name: "dashboard" });
      }
    },
    Login() {
      this.rut.error = "";
      this.text_password.error = "";

      if (this.$refs.login.validate()) {
        const user = this.rut.value.replace(/[^A-Z0-9]/gi, "");
        this.isloading = true;

        this.$store
          .dispatch("login", {
            username: user,
            password: this.text_password.text,
          })
          .then(async (response) => {
            if (response.userData.cambiar_clave) {
              this.$store.commit("SET_CLAVE_TEMPORAL", this.text_password.text);
              this.$router.push({ name: "NewPassword" });
            } else {
              if (response.canal.length > 0) {
                this.$store.commit("setChannels", response.canal);
                await this.$store.dispatch(
                  "REQUEST_CHANNEL_INFO",
                  response.canal[0]
                );
                this.islogged();
              } else {
                this.$router.push({ name: "Error", query: { codigo: 11 } });
              }
            }
          })
          .catch((error) => {
            if (!error?.onInput) {
              this.$store.commit("setSnackbar", {
                active: true,
                text:
                  error?.message ??
                  "Ops! Ha ocurrido un error en la autenticación.",
                top: true,
                color: error?.type ?? "error",
              });
            } else {
              this.error = true;
              switch (error.input) {
                case "username":
                  this.rut.error = error.message;
                  break;
                case "password":
                  this.text_password.error = error.message;
                  break;

                default:
                  break;
              }
            }
          })
          .finally(() => (this.isloading = false));
      }
    },
  },
};
</script>

<style scoped>
v-router-link,
li,
a {
  color: #0077c8;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
p.slogan {
  font-family: "SansProSemiBold";
  font-weight: 600;
  font-size: 36px;
  line-height: 20px;
  color: #223a65;
  text-align: center;
}
.mtp-10 {
  margin-top: 10%;
}
.login {
  color: #223a65;
  text-align: center;
}
</style>

import dayjs from "dayjs";
import { dateInput2Date } from "./dates";

export const validarContrasena = (pass = "") => {
  const patternList = [
    {
      // Al menos 8 caracteres
      regex: /^.{8,}$/,
      message: "Deben ser igual o mayor a 8 digitos",
    },
    {
      // Al menos una mayuscula y minuscula
      regex: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
      message:
        "La contraseña debe tener al menos una letra mayúscula y una minúscula.",
    },
    {
      // Al menos un numero
      regex: /^(?=.*\d).+$/,
      message: "La contraseña debe tener al menos un número.",
    },
  ];
  let patternMessage = true;
  for (const i in patternList) {
    if (!patternList[i].regex.test(pass)) {
      patternMessage = patternList[i].message;
      break;
    }
  }

  return patternMessage;
};

export const validarRut = (rut = "") => {
  const value = rut.replace(/[^A-Z0-9]/gi, "");

  const cuerpo = value.slice(0, -1);
  let dv = value.slice(-1).toUpperCase();

  let suma = 0;
  let multiplo = 2;

  for (let i = 1; i <= cuerpo.length; i++) {
    const index = multiplo * Number(value.charAt(cuerpo.length - i));

    suma += index;

    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }

  let result = true;
  const dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  if (dv === "K") {
    dv = 10;
  } else if (parseInt(dv) === 0) {
    dv = 11;
  }
  if (dvEsperado !== parseInt(dv)) {
    result = "RUT inválido";
  }
  // Validar que el Cuerpo coincide con su Dígito Verificador
  return result;
};

export const validarRutTipo = (rut = "") => {
  const str = parseInt(rut.substring(0, 2), 10);
  let type = false;
  // True: Juridico, False: Natural
  if (str >= 50 && rut.length === 9) {
    type = true;
  }
  return type;
};

export const validarRutFormat = (rut = "") => {
  const pattern = /^\d{1,2}\d{3}\d{3}[0-9kK]{1}$/;
  const patternTwo = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;

  let result = true;
  const test1 = pattern.test(rut);
  const test2 = patternTwo.test(rut);

  if (!test1 && !test2) {
    result = "Formato de RUT inválido";
  }

  return result;
};

export const validarFactura = (factura = "") => {
  const expReg1 = /^[a-z]{4}\d{2}$/i; // Formato "ABCD12" o "abcd12"
  const expReg2 = /^[a-z]{2}\d{4}$/i; // Formato "AB1234" o "ab1234"
  const expReg3 = new RegExp(["^factura$"].join(""), "i"); // Formato "FACTURA" o "factura"

  const test1 = expReg1.test(factura);
  const test2 = expReg2.test(factura);
  const test3 = expReg3.test(factura);

  return test1 || test2 || test3;
};

export const validarFecha = (fecha = "") => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Formato dd/mm/yyyy numerico
  if (RegExp(regex).exec(fecha) === null) {
    return false;
  }

  const [day, month, year] = fecha.split("/");
  const isoFormattedStr = `${year}-${month}-${day}`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
};

export const validarFecha18 = (fecha = "") => {
  const fechaSplit = fecha.split("/");
  const fechaNueva = `${fechaSplit[2]}-${fechaSplit[1]}-${fechaSplit[0]}`;
  const today = dayjs();
  const birthDate = dayjs(fechaNueva);
  let result = false;
  if (today.diff(birthDate, "year", true) >= 18) {
    result = true;
  }
  return result;
};

export const validarFechaMMYY = (fecha = "") => {
  const regex = /^\d{2}\/\d{2}$/; // Formato mm/yy numerico
  if (!RegExp(regex).exec(fecha)) {
    return false;
  }

  const [month, year] = fecha.split("/");
  const isoFormattedStr = `20${year}-${month}-01`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
};

export const validarFechaExpiracion = (fecha = "", mRel = 0) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + mRel);

  const [month, year] = fecha.split("/");
  const isoFormattedStr = `20${year}-${month}-01`;
  const date = new Date(isoFormattedStr);

  return date > currentDate;
};

export const validarEmail = (email = "") => {
  // Formato example@domain.ext
  const regex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const validarNumerico = (num = "") => {
  // Formato solo numeros
  let result = true;
  const pattern = /^\d+$/;
  if (!pattern.test(num)) {
    result = "El campo debe contener solo números.";
  }
  return result;
};

export const validarMonto = (num = "") => {
  let result = true;
  const pattern = /^\d{1,3}(\.\d{3})*(,\d{2})?$/;
  if (!pattern.test(num)) {
    result = "Debe escribir un valor numerico para el monto.";
  }
  return result;
};

export const validarNumeroTarjeta = (num = "") => {
  const regex =
    /^(?:4\d{3}|5[1-5]\d{2}|6011|3[47]\d{2})([- ]?)\d{4}\1\d{4}\1\d{4}$/;
  return regex.test(num);
};

export const validarMinStringLimit = (text = "", limit = 0) => {
  let result = true;
  if (text.length < limit) {
    result = `El campo debe contener al menos ${limit} caracteres.`;
  }
  return result;
};

export const validarAlphaNum = (text = "") => {
  let result = true;
  // Expresión regular que verifica si la cadena contiene solo letras y números
  const regex = /^[a-zA-Z0-9]+$/;
  if (!regex.test(text)) {
    result = "El campo solo puede contener letras o numeros";
  }
  return result;
};

export const validarFechaRango = (fecha = new Date(), dias = 60) => {
  const end = new Date();
  end.setDate(end.getDate() + dias);

  const fechaInicio = new Date().setHours(0, 0, 0, 0);
  const fechaFin = new Date(end).setHours(0, 0, 0, 0);

  const date = dateInput2Date(fecha);

  const isValid =
    fechaInicio.valueOf() <= date.valueOf() &&
    date.valueOf() <= fechaFin.valueOf();

  return isValid;
};

export default {
  validarContrasena,
  validarRutFormat,
  validarFactura,
  validarFecha,
  validarEmail,
  validarNumerico,
};
